<template>
  <div class="installations container-fluid min-vh-100">
    <vue-headful
      title="Install-HC - Les Installations Home-Cinema de tous les membres"
      description="Liste complètes des Installations Home-Cinema présentées sur le site"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>
    <InstallationsList/>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
import InstallationsList from '@/components/InstallationsList.vue'
export default { 
  name: 'Installations',
  components: {
    Menu,
    InstallHC,
    InstallationsList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

a {
  height:103px;
  width:136px;
  display:block;
  text-decoration: none;
}

img {
  padding-top:3px;
  padding-left:3px;
}

.installations {
  width:100%;
  margin:0 auto;
  margin-top:0;
  padding:0;
}

</style>
