<template>
  <div v-if="isShown">
    <h3 v-if="fav">Vos installations favorites</h3>
    <h3 v-else>Les installations Home-Cinema des membres</h3>
    <Pub/>
    <div>
      <div class="filtre">Installation :
        <a href="#" :id="roundId()" class="menu dedier hideable" v-on:click="applyFilter('dediee','yes')">Dédiée</a>
        <a :id="roundId()" class="menu dedier hideable" href="#" v-on:click="applyFilter('dediee','no')">Pièce de vie</a>
      </div>
      <div class="filtre">Superficie :
        <a :id="roundId()" href="#" class="menu surface hideable" v-on:click="applyFilter('surface',0)">&lt;12m²</a>
        <a :id="roundId()" class="menu surface hideable" href="#" v-on:click="applyFilter('surface',12)">12 à 25m²</a>
        <a :id="roundId()" class="menu surface hideable" href="#" v-on:click="applyFilter('surface',25)">&gt;25m²</a>
      </div >
      <div class="filtre">Budget :
        <a href="#" :id="roundId()" class="menu budget hideable" v-on:click="applyFilter('budget',0)">&lt;1500€</a>
        <a href="#" :id="roundId()" class="menu budget hideable" v-on:click="applyFilter('budget',1)">1500 à 3000€</a>
        <a href="#" :id="roundId()" class="menu budget hideable" v-on:click="applyFilter('budget',2)">3000 à  5000€</a>
        <a href="#" :id="roundId()" class="menu budget hideable" v-on:click="applyFilter('budget',3)">5000 à 8000€</a>
        <a href="#" :id="roundId()" class="menu budget hideable" v-on:click="applyFilter('budget',4)">8000 à 12000€</a>
        <a href="#" :id="roundId()" class="menu budget hideable" v-on:click="applyFilter('budget',5)">&gt;12000€</a>
      </div>
      <h4><a class="menu" href="#" v-on:click="applyFilter('init','init')">Réinitialiser tous les filtres</a></h4>
    </div>
    <div class="whitebg preview shadow d-inline-block" v-for="installations in json" v-bind:key=installations.ID>
      <div :class="'dediee_' + installations.Dedier + ' surface_' + installations.surface + ' budget_' + installations.budget">
        <router-link :to="{path: 'presentation/' + installations.secureProprio }">
          <v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/apercevoir/' + installations.vignette " :alt="'Installation de ' + installations.Proprio"/>
        </router-link>
      </div>
      <h4><SvgPathIcon name="login-7" /><span>{{installations.Proprio}}</span></h4>
      <h5><span v-if="installations.speaker != '999'"><SvgPathIcon name="speaker-8" /><span class="confHp">{{ installations.conf_hp}}</span></span>&nbsp;<span title="Surface" class="" v-if="installations.surface != '999'">{{ installations.surface}}m²</span>&nbsp;<span title="Budget" class="budget" v-if="installations.budget != '999'"><span title="Budget" v-html="manageCoin(installations.budget)"></span></span>&nbsp;<span title="Salle dédiée" class="dedie" v-if="installations.Dedier === 'oui'">&nbsp;</span></h5>
    </div>
  </div>  
</template>

<script>
import VLazyImage from 'v-lazy-image'
import SvgPathIcon from "@/components/SvgPathIcon.vue"
import axios from 'axios'
export default { 
  name: 'InstallationsList',
  components: {    
    SvgPathIcon,
    VLazyImage
    //axios
  },
  props: {
    fav: String
  },
  data() {         
    return {
      json: null,
      firstJson: null,
      images: [],
      dediee: 'init',   
      iconNames: [
        "login-7",
        "speaker-8"
      ]   
    }    
  },
  mounted() {      
    if(this.fav) {
      //var membre = this.fav.replace(/ /gi,'___')
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/liste/fav/desc').then(response => {this.json = response.data.installations;})    
    }
    else {
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/liste/toutes/desc').then(response => {this.json = response.data.installations;})    
    }    
  },
  computed:{
    isShown: function() {
      if(this.json && this.json.length!=0) {
        return true
      } else {
        return false
      }
    }
  },
  methods:{
    roundId: function() {
      return Math.round(Math.random() * 1000000)
    },
    applyFilter: function(section, choice) {
      if(this.firstJson === null) {
        this.firstJson = this.json
      }
      if (choice === 'init') {
        var all = document.getElementsByClassName('hideable');
        for (var i = 0; i < all.length; i++) {
          all[i].style.display = 'inline-block';
        }
        this.json = this.firstJson
      } else {
        this.json = this.getFilter(section, choice, event)
      }
    },
    getFilter: function(section, choice,event) {      
      var i;
      var clickedElement = event.target.id; 
      var classElement = event.target.className;
      var all = document.getElementsByClassName(classElement);      
      for (i = 0; i < all.length; i++) {
        if(all[i].id != clickedElement) {
          all[i].style.display = 'none';
        }
      }     
      if(section === "dediee") {
        if(choice === "yes") {          
          return this.json.filter(function(i) {
            return i.Dedier === "oui"            
          })        
        } 
        else if(choice === "no") {
          return this.json.filter(function(i) {
            return i.Dedier === "non"
          })
        }         
      } 
      else if(section === "budget") {        
        return this.json.filter(function(i) {
          return i.budgetRange === choice
        })
      }
      else if(section === "surface") {        
        if(choice === 0) {
          return this.json.filter(function(i) {
            return i.surface < 12
          })
        }
        else if (choice === 12 ) {
          return this.json.filter(function(i) {
            return i.surface < 25 && i.surface > 12
          })
        }
        else if (choice === 25 ) {
          return this.json.filter(function(i) {
            return i.surface > 25
          })
        }
      }
    },
    manageCoin: function(str){
      let coin;
      if(str === '0') {
        coin = "$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
      } 
      else if (str === '1500') {
        coin = "$$&nbsp;&nbsp;&nbsp;&nbsp;";
      }
      else if (str === '3000') {
        coin = "$$$&nbsp;&nbsp;&nbsp;";
      }
      else if (str === '5000') {
        coin = "$$$$&nbsp;&nbsp;";
      }
      else if (str === '8000') {
        coin = "$$$$$&nbsp;";
      }
      else if (str === '12000') {
        coin = "$$$$$$";
      }      
      return coin;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

a {
  height:103px;
  width:136px;
  display:block;
  text-decoration: none;
}

/* css for a in menu */
.menu {
  display:inline-block;
  height:20px;
  line-height: 20px;
  width:inherit;
  height:inherit;
  border-radius: 4px;
  background: #ddd;
  padding:3px;
  margin:3px;
}


img {
  padding-top:3px;
  padding-left:3px;
}

h3 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

h4 {
  font-size:1em;
  padding-left:5px;
}

h4 span {
  margin-left:-8px;
}

h5 {
  font-family: "Odibee Sans", Helvetica, Arial, sans-serif;
  font-size:0.65em;
  padding-left:3px;
}

h5 span {
  border:none;
  border-radius:3px;
  padding:2px;
  background:#ddd;
}

.preview {
  width:139px;
  height:157px;
  overflow:hidden;
  margin:5px;
  text-align:left;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.5s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

svg {
  margin-bottom: -4px;
  margin-left:1px;
  opacity: 0.5;
}

.confHp {
  border:none;
  margin-left:-15px;
}

.budget {
  font-weight: 500;
}

.budget span {
  border:none;
  color:#007bff;
}

.dedie {
  background:url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAUCAQAAAD8O3+kAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxIOFTdUUgOYAAAA1UlEQVQoz3XRvUoDQRhG4WfiFrJBWCGWYgiIlaaysLXQzvuxE+xyK3ZWomAjNjbamEIjESLY2EQJCP6Mxa4Smc07xcAcXjjfNwHzDnRNZ2jfGA7F5BzRQEeabokmNeitRHWJs5EShZr3UKKlGrRcXpkt51Pi17a1CPa03LtwZxWMFTas+2AiijitOs+IovfMyJpvnPkEAzxqG9IXRZsKuVxuQduLqJ9VRlf4qsYJv3bFn/DcP/1mw2DGMl6DRTtWEvDkMiC4TdCuEfRqvvKYDA9OktYNP5OESLg+B4gOAAAAAElFTkSuQmCC) no-repeat;
  height:20px;
  width:13px;
  display:inline-flex;
  opacity:0.4;
}

.filtre {
  display:inline-block;
  margin-right:10px;
}

</style>
